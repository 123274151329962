<template>
    <div class="container-fluid flex-column" style="height:100vh">
        <div class="row-fluid" style="height:50px"></div>
        <div style="margin-top:40px" class="row-fluid p-3">
            <h1>ASSISTENZA</h1>
        </div>
        <div class="row bg-white align-self-stretch flex-grow-1 d-flex flex-column align-items-center">
            <div class="p-5 w-100 text-center">
                <Intro/>
                <div class="text-left">

                    <h2>Hai bisogno di assistenza o vuoi scambiare due chiacchiere?</h2> <!--TODO: Cambiare titolo -->
                    <p>Hai bisogno di assistenza per selezionare componenti compatibili con macOS, richiedere una EFI per la tua configurazione hardware o vuoi semplicemente scambiare quattro chiacchiere con noi? Che aspetti, clicca sul link:</p>
                    <h5><a href="https://discord.hackintoshitalia.it" target="_blank">SERVER DISCORD</a></h5>
                </div>
                <div class="mt-3 text-left">
                    <h2>Quali componenti acquistare</h2>
                    <p class="text-left">Sulla pagina delle <span class="text-primary"><a href="/guide" target="_blank">Guide</a></span> troverete una lista aggiornata dei componenti compatibili e ottimali</p>
                    <hr>
                    <h2>Assemblaggio PC</h2>
                    <p>Per gli utenti più inesperti e incapaci di assemblare personalmente un PC, lo Staff di Hackintosh Italia è pronto a seguirli passo per passo durante la procedura o anche assemblarlo personalmente e spedirlo*.</p>
                </div>
                <p style="background-color:#222222;" class="text-justify p-5 text-white mb-5 mt-4"><small>*Ogni spesa di spedizione e tassa PayPal sarà a carico del destinatario. Il servizio di assemblaggio è totalmente gratuito, ed al solo scopo di aiutare e far crescere la community. Vi preghiamo di segnalare allo Staff chiunque (sia membri dello Staff che utenti) provi a truffarvi in alcun modo. Ogni procedura di invio e ricezione di denaro DOVRÀ essere eseguito utilizzando PayPal e descrivendo con ASSOLUTA PRECISIONE le specifiche del PC, in modo da tutelare al massimo entrambe le parti.
Gli unici autorizzati a fornire tale prestazione sono SOLO ed ESCLUSIVAMENTE i membri dello Staff specificatamente indicati e abilitati a farlo, i quali DOVRANNO preventivamente identificarsi all’utente destinatario tramite una foto in primo piano nella quale mostrano il volto ed un documento d’identità valido ed una foto del documento stesso.</small></p>
                <hr>
            </div>
        </div>
    </div>
</template>

<script>
import Intro from '../components/Intro.vue'

export default {
    name: 'Assistenza',
    metaInfo:{
        title: 'Hackintosh Italia',
        titleTemplate: '%s - Assistenza',
        meta:[
            {charset: 'utf-8'},
            {name: 'keywords', content:"Hackintosh, Italia, Assistenza"},
            {name: 'description', content:'Hai bisogno di assistenza? Potrete chiedere ad un membro dello Staff di preparare una build o una EFI tramite il nostro server Discord.'}
        ]
    },
    components: {
        Intro
    }
}
</script>